import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleNewsletterSubmit = async () => {
        if (!email) {
            toast.error(t('FORM_NEWSLETTER_SUBSCRIPTION_ERROR_EMPTY'));
            return;
        }
    
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            toast.error(t('FORM_NEWSLETTER_SUBSCRIPTION_ERROR_INVALID'));
            return;
        }
    
        setLoading(true);
    
        console.log('email: ', email);
        try {
            await axios.post(`${process.env.REACT_APP_SERVER_API_ENDPOINT}/newsletter`, { email });
            toast.success(t('FORM_NEWSLETTER_SUBSCRIPTION_SUCCESS'));
            setEmail('');
        } catch (error) {
            toast.error(t('FORM_NEWSLETTER_SUBSCRIPTION_ERROR'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <section className='pb-6'>
                <Container>
                    <Row className='mb-10'>
                        <Col className='mb-4 mb-lg-0' lg={3}>
                            <img src="./../images/purple_logo.svg" className='mb-3' width={50} height={50} alt="" />
                            <p className='text__16'>{t("FOOTER_DESC")}</p>
                        </Col>
                        <Col className='col-6 mb-4 mb-lg-0' lg={{ span: 2, offset: 1 }}>
                            <h5 className='font-medium text__16 mb-3 text-[#A3A3A3]'>{t("FOOTER_NAV_COMPANY_TITLE")}</h5>
                            <div className="flex flex-wrap gap-3 font-medium text__16">
                                {/* <NavLink to="/services" className='inline-block w-full text-Mblack'>{t("services")}</NavLink> */}
                                {/* <NavLink to="/about" className='inline-block w-full text-Mblack'>{t("about_us")}</NavLink> */}
                                <NavLink to="/contact" className='inline-block w-full text-Mblack'>{t("FOOTER_NAV_CONTACT")}</NavLink>
                                {/* <NavLink to="/join-team" className='inline-block w-full text-Mblack'>Our team</NavLink> */}
                                <NavLink to="/faq" className='inline-block w-full text-Mblack'>{t("FOOTER_NAV_FAQ")}</NavLink>
                            </div>
                        </Col>
                        <Col className='col-6 mb-4 mb-lg-0' lg={2}>
                            <h5 className='font-medium text__16 mb-3 text-[#A3A3A3]'>{t("FOOTER_NAV_LINK_TITLE")}</h5>
                            <div className="flex flex-wrap gap-3 font-medium text__16">
                                <NavLink to="/privacy" className='inline-block w-full text-Mblack'>{t("FOOTER_NAV_PRIVACY")}</NavLink>
                                <NavLink to="/terms" className='inline-block w-full text-Mblack'>{t("FOOTER_NAV_TERMS")}</NavLink>
                            </div>
                        </Col>
                        <Col className='mb-4 mb-lg-0' lg={4}>
                            <h5 className='font-medium text__16 mb-3'>{t("FOOTER_NEWSLETTER_TITLE")}</h5>
                            <div className="flex items-center gap-3">
                                <input
                                    type="email"
                                    className='w-full h-[46px] border-l-0 border-b-[1px] border-Mblack text__14 outline-none focus:outline-none active:outline-none'
                                    placeholder={t("FOOTER_NEWSLETTER_EMAIL")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={loading}
                                />
                                <button
                                    className='font-medium text-[14px] bg-Mpurple hover:bg-Mpurple/90 text-Mwhite px-4 py-2 rounded-full'
                                    onClick={handleNewsletterSubmit}
                                    disabled={loading}
                                >
                                    {loading ? t('FOOTER_NEWSLETTER_LOADING') : t("FOOTER_NEWSLETTER_BUTTON")}
                                </button>
                            </div>
                        </Col>
                    </Row>

                    <div className="relative flex flex-wrap lg:flex-nowrap items-center justify-center lg:justify-between gap-4">
                        <p className='text__16 text-[#A3A3A3]'>© 2024 <span className='font-semibold'>Talynx</span>, made with 💜 by Tensor AI.</p>

                        <div className="flex justify-center items-center gap-3 lg:absolute lg:left-1/2 lg:top-1/2 lg:-translate-x-1/2 lg:-translate-y-1/2 w-full lg:w-auto">
                            <a href="https://x.com/tensor_ai" target='blank'>
                                <img src="./../images/networks/x.svg" alt="" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCQtxv0vypHqY3WsjRPkj3EQ" target='blank'>
                                <img src="./../images/networks/youtube.svg" alt="" />
                            </a>
                            <a href="https://www.instagram.com/tensor_ai" target='blank'>
                                <img src="./../images/networks/instagram.svg" alt="" />
                            </a>
                            <a href="https://www.facebook.com/tensorai" target='blank'>
                                <img src="./../images/networks/facebook.svg" alt="" />
                            </a>
                            <a href="https://www.linkedin.com/company/tensor-ai" target='blank'>
                                <img src="./../images/networks/linkedin.svg" alt="" />
                            </a>
                        </div>

                        <div className="flex items-center gap-3">
                            <NavLink to="/privacy" className='font-medium text__16 text-[#525252]'>{t("FOOTER_NAV_PRIVACY")}</NavLink>
                            <NavLink to="/terms" className='font-medium text__16 text-[#525252]'>{t("FOOTER_NAV_TERMS")}</NavLink>
                        </div>
                    </div>
                </Container>
            </section>
        </Fragment>
    )
}

export default Footer
