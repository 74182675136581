import React from 'react'
import { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <HeadTitle text={t("PRIVACY_TITLE")} sub={t("PRIVACY_DATE")} />

            <section>
                <Container>
                    <Row className='justify-center'>
                        <Col md={10}>
                            <h5 className='font-medium text-[16px] lg:text-[18px] mb-3'>{t("PRIVACY_INTRO")}</h5>

                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>{t("PRIVACY_COLLECTED_INFO_TITLE")}</h5>
                                <p className='text-[14px] lg:text-[16px]'>{t("PRIVACY_COLLECTED_INFO_DESC")}</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>{t("PRIVACY_INFO_USAGE_TITLE")}</h5>
                                <p className='text-[14px] lg:text-[16px]'>{t("PRIVACY_INFO_USAGE_DESC")}</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>{t("PRIVACY_DISCLOSURE_TITLE")}</h5>
                                <p className='text-[14px] lg:text-[16px]'>{t("PRIVACY_DISCLOSURE_DESC")}</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>{t("PRIVACY_SECURITY_TITLE")}</h5>
                                <p className='text-[14px] lg:text-[16px]'>{t("PRIVACY_SECURITY_DESC")}</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>{t("PRIVACY_CHILDREN_TITLE")}</h5>
                                <p className='text-[14px] lg:text-[16px]'>{t("PRIVACY_CHILDREN_DESC")}</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>{t("PRIVACY_CHANGES_TITLE")}</h5>
                                <p className='text-[14px] lg:text-[16px]'>{t("PRIVACY_CHANGES_DESC")}</p>
                            </div>
                            <div className="mb-3">
                                <h5 className='font-medium text-[16px] lg:text-[18px] mb-2'>{t("PRIVACY_CONTACT_TITLE")}</h5>
                                <p className='text-[14px] lg:text-[16px]'>{t("PRIVACY_CONTACT_DESC")}</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default Privacy
