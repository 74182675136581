import React from 'react';
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const GetStarted = ({ title, sub, showBtn = true, btnTitle, btnLink }) => {
    const { t } = useTranslation();

    return (
        <section className='bg-Mpurple overflow-hidden relative'>
            <img src="./../images/white-star-left.png" className='absolute w-[9rem] sm:w-[12rem] md:w-[20rem] xl:w-auto left-4 top-0' alt="" />
            <img src="./../images/white-star-right.png" className='absolute w-[9rem] sm:w-[12rem] md:w-[20rem] xl:w-auto right-4 bottom-0' alt="" />
            <Container className='relative z-[2]'>
                <div className="text-center text-Mwhite">
                    <h2 className='font-medium text__48 mb-2'>{title ? title : t('FOOTER_BAND_TITLE')}</h2>
                    <p className='text__16 mb-8'>{sub ? sub : t('FOOTER_BAND_DESC')}</p>
                    {showBtn && <NavLink to={btnLink ? btnLink : "/contact"} className='inline-block cursor-pointer font-medium text__16 text-Mblack bg-Mgray !border-Mgray btnClass'>{btnTitle ? btnTitle : t('FOOTER_BAND_BUTTON')}</NavLink>}
                </div>
            </Container>
        </section>
    );
}

export default GetStarted;
