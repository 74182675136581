import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const YouDo = () => {
    const { t } = useTranslation();

    return (
        <section>
            <Container>
                <div className="text-center mb-10">
                    <h2 className='font-medium text__48 mb-2'>{t('WHAT_YOU_CAN_DO')}</h2>
                    <p className='text__16'>
                        {t('WHAT_YOU_CAN_DO_DESC_LINE_1')} <br className='hidden sm:block' /> {t('WHAT_YOU_CAN_DO_DESC_LINE_2')}
                    </p>
                </div>

                <Row>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4} data-aos="zoom-in" data-aos-duration="2000">
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mpurple mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/icons/favorite-chart.svg" alt="" />
                            </div>
                            <h4 className='font-medium text__24 mb-3'>{t('WHAT_YOU_CAN_DO_1_Title')}</h4>
                            <p className='text__16'>{t('WHAT_YOU_CAN_DO_1_Description')}</p>
                        </div>
                    </Col>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4} data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="300">
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mpurple mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/icons/graph.svg" alt="" />
                            </div>
                            <h4 className='font-medium text__24 mb-3'>{t('WHAT_YOU_CAN_DO_2_Title')}</h4>
                            <p className='text__16'>{t('WHAT_YOU_CAN_DO_2_Description')}</p>
                        </div>
                    </Col>
                    <Col className='mb-4 lg:mb-0' md={6} lg={4} data-aos="zoom-in" data-aos-duration="2000" data-aos-delay="400">
                        <div className="bg-[#FAFAFA] p-4 rounded-[40px] h-full">
                            <div className="w-[48px] xl:w-[64px] h-[48px] xl:h-[64px] rounded-full flex items-center justify-center bg-Mpurple mb-8">
                                <img className='w-[24px] xl:w-[32px] h-[24px] xl:h-[24px]' src="./../images/icons/3dcube.svg" alt="" />
                            </div>
                            <h4 className='font-medium text__24 mb-3'>{t('WHAT_YOU_CAN_DO_3_Title')}</h4>
                            <p className='text__16'>{t('WHAT_YOU_CAN_DO_3_Description')}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default YouDo;
