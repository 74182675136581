import React from 'react'
import { Container } from 'react-bootstrap'

const HeadTitle = ({ text, sub }) => {
    return (
        <section className='relative'>
            <img src="./../images/Vector.svg" className='absolute w-[4rem] md:w-[7rem] lg:w-auto left-8 top-4' alt="" />
            <img src="./../images/Vector.svg" className='absolute w-[4rem] md:w-[7rem] lg:w-auto right-8 bottom-4' alt="" />
            <Container className='relative z-[2]'>
                <div className="text-center">
                    <h1 className='font-medium text__64' dangerouslySetInnerHTML={{ __html: text }} />
                    {
                        sub ? <p className='text__16 text-[#525252] mt-2' dangerouslySetInnerHTML={{ __html: sub }} /> : ""
                    }
                </div>
            </Container>
        </section>
    )
}

export default HeadTitle
