import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PriceList from '../Components/Pages/PriceList';
import { CarouselBrand } from '../Components/Carousel/CarouselBrand'
import YouDo from '../Components/Pages/YouDo';
import GetStarted from '../Components/Pages/GetStarted';

const Homepage = () => {
    const { t } = useTranslation();

    const dataUsedCreator = [
        {
            title: t('COMPANY_TYPE_1_TITLE'),
            desc: t('COMPANY_TYPE_1_DESC')
        },
        {
            title: t('COMPANY_TYPE_2_TITLE'),
            desc: t('COMPANY_TYPE_2_DESC')
        },
        {
            title: t('COMPANY_TYPE_3_TITLE'),
            desc: t('COMPANY_TYPE_3_DESC')
        },
        {
            title: t('COMPANY_TYPE_4_TITLE'),
            desc: t('COMPANY_TYPE_4_DESC')
        },
    ];

    const [ToogleAccordion, setToogleAccordion] = useState("Coaches");

    const setToogleAccordionWrap = (e) => {
        if (e === ToogleAccordion) {
            setToogleAccordion("");
        } else {
            setToogleAccordion(e);
        }
    };

    return (
        <Fragment>
            {/* start:hero */}
            <section className='pt-16 pb-16 md:py-32 bg-Mpurple relative overflow-hidden flex items-center' style={{ marginTop: '-0.1%' }}>
                <Container className='relative z-[2]'>
                    <Row className='align-items-center'>
                        <Col md={6} className='text-left'>
                            <h1 className='font-medium text__64 text-Mwhite mb-6' data-aos="fade-right" data-aos-duration="1000">
                                {t('HERO_TITLE')}
                            </h1>
                            <p className='text__20 text-Mwhite mb-8' data-aos="fade-right" data-aos-duration="1000" data-aos-delay="200">
                                {t('HERO_SUBTITLE_LINE_1')}
                                <br />
                                {t('HERO_SUBTITLE_LINE_2')}
                                <br />
                                {t('HERO_SUBTITLE_LINE_3')}
                            </p>

                            <NavLink 
                                to="/contact" 
                                className='inline-block cursor-pointer font-medium text__18 text-Mblack bg-Mgray !border-Mgray btnClass px-8 py-3'
                                data-aos="fade-up" 
                                data-aos-duration="1000" 
                                data-aos-delay="400"
                            >
                                {t('HERO_DEMO_BUTTON')}
                            </NavLink>
                        </Col>
                        <Col md={6} className='text-center relative hidden md:block' style={{ height: '500px' }}>
                            <div className="image-stack">
                                <img 
                                    src="./../images/hero-image-1.png" 
                                    alt="Hero Image 1" 
                                    className='absolute top-0 left-1/3 w-1/5 h-auto z-30 shadow-lg rounded-lg'
                                    data-aos="fade-left" 
                                    data-aos-duration="1000" 
                                    data-aos-delay="100"
                                />
                                <img 
                                 _ik   src="./../images/hero-image-2.png" 
                                    alt="Hero Image 2" 
                                    className='absolute top-1/4 left-1/4 w-3/4 h-auto z-20 shadow-lg rounded-lg'
                                    data-aos="fade-left" 
                                    data-aos-duration="1000" 
                                    data-aos-delay="500"
                                />
                                <img 
                                    src="./../images/hero-image-3.png" 
                                    alt="Hero Image 3" 
                                    className='absolute top-1/2 left-3/4 w-1/2 h-auto z-30 shadow-lg rounded-lg'
                                    data-aos="fade-left" 
                                    data-aos-duration="1000" 
                                    data-aos-delay="700"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* end:hero */}

            <YouDo />

            <section>
                <Container>
                    <Row>
                        <Col className='mb-4 md:mb-0' md={6} data-aos="fade-up-right" data-aos-duration="2000">
                            <div className="mb-10">
                                <h2 className='font-medium text__48 mb-2'>{t('COMPANY_TYPE_TITLE')}</h2>
                                <p className='text__16'>{t('COMPANY_TYPE_DESC')}</p>
                            </div>

                            <div className="flex flex-wrap gap-3">
                                {
                                    dataUsedCreator.map((obj, index) => {
                                        return <div key={index} className={"p-4 rounded-2xl w-full cursor-pointer " + (ToogleAccordion === obj.title ? "bg-[#FAFAFA]" : "border border-solid border-[#F5F5F5]")} onClick={() => setToogleAccordionWrap(obj.title)}>
                                            <div className="flex items-center justify-between">
                                                <h5 className='font-medium text__20'>{obj.title}</h5>
                                                <img src="./../images/icons/chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion === obj.title ? "rotate-180" : "")} alt="" />
                                            </div>
                                            <div className={"py-2 " + (ToogleAccordion === obj.title ? "block" : "hidden")}>
                                                <p className='text__16'>{obj.desc}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </Col>
                        <Col md={6} className='text-right mt-auto'>
                            <div className='p-10 mb-4 bg-gray-50 rounded-lg'>
                                <img src="./../images/dashboard.png" alt="" data-aos="fade-up-left" data-aos-duration="2000" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section data-aos="fade-up" data-aos-duration="2000">
                <Container>
                    <Row>
                        <Col className='my-auto md:!order-1 order-2' md={6}>
                            <div className='bg-gray-100 p-5 rounded-lg'>
                                <img src="./../images/candidate-block.png" alt="" />
                            </div>
                        </Col>
                        <Col className='mb-4 md:my-auto order-1 md:!order-2' md={6}>
                            <h2 className='font-medium text__48 mb-2'>{t('TRIAL_PROPOSITION_TITLE')}</h2>
                            <p className='text__16 mb-8'>{t('TRIAL_PROPOSITION_DESC')}</p>
                            <NavLink to="/contact" className='inline-block cursor-pointer font-medium text__16 text-Mwhite bg-Mpurple !border-Mpurple btnClass'>
                                {t('TRIAL_PROPOSITION_BUTTON')}
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section data-aos="fade-down" data-aos-duration="2000">
                <Container>
                    <PriceList />
                </Container>
            </section>

            {/* <section data-aos="zoom-out-right" data-aos-duration="2000">
                <Container>
                    <Row>
                        <Col className='my-auto md:!order-1 order-2' md={6}>
                            <img src="./../images/selected.png" alt="" />
                        </Col>
                        <Col className='mb-4 md:my-auto order-1 md:!order-2' md={6}>
                            <h2 className='font-medium text__48 mb-2'>{t('increase_conversions_title')}</h2>
                            <p className='text__16'>
                                {t('increase_conversions_desc')}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* <section className='bg-[#FAFAFA] overflow-hidden'>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='font-medium text__48 mb-2'>They trust us</h2>
                        <p className='text__16'>Hear are our satisfied clients, we've helped them take their <br /> businesses to new heights.</p>
                    </div>

                    <div className="flex justify-center flex-wrap gap-3 lg:gap-6 mb-10">
                        <img src="./../images/partners/partner_1.png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                        <img src="./../images/partners/partner_2.png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                        <img src="./../images/partners/partner_3.png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                        <img src="./../images/partners/partner_4.png" className='w-[9rem] lg:w-[10rem] h-fit xl:h-auto xl:w-auto' alt="" />
                    </div>
                </Container>
                <CarouselBrand />
            </section> */}

            <GetStarted />
        </Fragment>
    );
}

export default Homepage;
