import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';

const ContactForm = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        message: ''
    });

    const [loading, setLoading] = useState(false);

    const schema = yup.object().shape({
        firstname: yup.string().required(t('FORM_ERROR_FIRSTNAME_REQUIRED')),
        lastname: yup.string().required(t('FORM_ERROR_LASTNAME_REQUIRED')),
        email: yup.string().email(t('FORM_ERROR_EMAIL_INVALID')).required(t('FORM_ERROR_EMAIL_REQUIRED')),
        message: yup.string().required(t('FORM_ERROR_MESSAGE_REQUIRED'))
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await schema.validate(formData, { abortEarly: false });
        } catch (validationErrors) {
            validationErrors.inner.forEach(error => {
                toast.error(t(error.message));
            });
            return;
        }

        try {
            const response = await axios.post(process.env.REACT_APP_SERVER_API_ENDPOINT +  "/contact", formData);
            if (response.status === 200) {
                toast.success(t('CONTACT_FORM_SUBMISSION_SUCCESS'));
                setFormData({
                    firstname: '',
                    lastname: '',
                    email: '',
                    message: ''
                });
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 409) {
                    toast.info(t('CONTACT_FORM_SUBMISSION_DUPLICATE'));
                    setFormData({
                        firstname: '',
                        lastname: '',
                        email: '',
                        message: ''
                    });
                } else if (error.response.status === 500) {
                    toast.error(t('FORM_ERROR_SERVER'));
                }
            } else {
                toast.error(t('FORM_ERROR_NETWORK'));
            }
        } finally {
            setLoading(false);
        }
    };

     return (
        <section>
            <Container>
                {/* <ToastContainer autoClose={3000} /> */}
                <Row className='justify-between'>
                    <Col className='mb-4 md:mb-0' md={4}>
                        <h2 className='font-medium text__48 mb-3'>{t('CONTACT_PAGE_H1_DESC')}</h2>
                        <p className='text__18 text-[#636366]'>{t('CONTACT_PAGE_H2_DESC')}</p>

                        <div className="my-4">
                            <p className='text__14 mb-2'>{t('CONTACT_PAGE_QUESTION')}</p>
                            <h5 className='font-medium text__20 text-Mpurple'>contact@talynx.io</h5>
                        </div>

                        <p className='text__14 mb-2'>{t('CONTACT_PAGE_SOCIAL_MEDIA')}</p>
                        <div className="flex items-center gap-2">
                            <a href="https://x.com/tensor_ai" target='blank'>
                                <img src="./../images/networks/x.svg" alt="" />
                            </a>
                            <a href="https://www.youtube.com/channel/UCQtxv0vypHqY3WsjRPkj3EQ" target='blank'>
                                <img src="./../images/networks/youtube.svg" alt="" />
                            </a>
                            <a href="https://www.instagram.com/tensor_ai" target='blank'>
                                <img src="./../images/networks/instagram.svg" alt="" />
                            </a>
                            <a href="https://www.facebook.com/tensorai" target='blank'>
                                <img src="./../images/networks/facebook.svg" alt="" />
                            </a>
                            <a href="https://www.linkedin.com/company/tensor-ai" target='blank'>
                                <img src="./../images/networks/linkedin.svg" alt="" />
                            </a>
                        </div>
                    </Col>
                    <Col md={7}>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col className='col-6'>
                                    <Form.Group className="mb-3" controlId="formFirstName">
                                        <Form.Label className='font-normal text__14 text-[#A3A3A3]'>{t('CONTACT_PAGE_FORM_FIRST_NAME')}<span className='text-[#ED544E]'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('CONTACT_PAGE_FORM_FIRST_NAME')}
                                            name="firstname"
                                            value={formData.firstname}
                                            onChange={handleChange}
                                            className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]'
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col className='col-6'>
                                    <Form.Group className="mb-3" controlId="formLastName">
                                        <Form.Label className='font-normal text__14 text-[#A3A3A3]'>{t('CONTACT_PAGE_FORM_LAST_NAME')}<span className='text-[#ED544E]'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder={t('CONTACT_PAGE_FORM_LAST_NAME')}
                                            name="lastname"
                                            value={formData.lastname}
                                            onChange={handleChange}
                                            className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]'
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>{t('CONTACT_PAGE_FORM_EMAIL')}<span className='text-[#ED544E]'>*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder={t('CONTACT_PAGE_FORM_EMAIL')}
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className='font-medium text__14 bg-[#FAFAFA] h-[54px] rounded-[20px] px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]'
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formMessage">
                                <Form.Label className='font-normal text__14 text-[#A3A3A3]'>{t('CONTACT_PAGE_FORM_MESSAGE')}<span className='text-[#ED544E]'>*</span></Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder={t('CONTACT_PAGE_FORM_MESSAGE_PLACEHOLDER')}
                                    name="message"
                                    value={formData.message}
                                    style={{ height: '100px' }}
                                    onChange={handleChange}
                                    className='font-medium text__14 bg-[#FAFAFA] rounded-[20px] py-3 px-3 outline-none shadow-none focus:outline-none focus:shadow-none border-[#F5F5F5] focus:border-[#F5F5F5] focus:bg-[#FAFAFA]'
                                    required
                                />
                            </Form.Group>

                            <button type="submit"  disabled={loading} className='inline-block cursor-pointer text-center font-medium text__16 text-Mwhite py-[15px] px-[28px] bg-Mpurple hover:bg-Mpurple/90 border-Mpurple btnClass md:w-auto w-full'>
                                {loading ? t('CONTACT_PAGE_FORM_LOADING') : t('CONTACT_PAGE_FORM_SUBMIT')}
                            </button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ContactForm;
