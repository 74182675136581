import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { useTranslation } from 'react-i18next';

import CookieBanner from './Components/CookieBanner'; 
import NotFound from './Pages/NotFound';
import './i18n';

import DefaultLayout from './Layouts/DefaultLayout';

import Homepage from './Pages/Homepage';
import Pricing from './Pages/Pricing';
import Services from './Pages/Solutions';
import PlansDetails from './Pages/PlansDetails';
import Payment from './Pages/Payment';
import About from './Pages/About';
import JoinTeam from './Pages/JoinTeam';
import JoinForm from './Pages/JoinForm';
import Blog from './Pages/Blog';
import BlogDetail from './Pages/BlogDetail';
import Contact from './Pages/Contact';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import Faq from './Pages/Faq';

import { ToastContainer } from 'react-toastify';

const App = () => {
  let location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const cachedLanguage = localStorage.getItem('i18nextLng');
    if (cachedLanguage && i18n.language !== cachedLanguage) {
      i18n.changeLanguage(cachedLanguage);
    }
  }, [i18n]);

  const pageTitles = {
    "/": "HOMEPAGE_TITLE",
    "/pricing": "PAGE_TITLE_Pricing",
    "/solutions": "PAGE_TITLE_Services",
    "/plans-details": "PAGE_TITLE_Plan_details",
    "/payment": "PAGE_TITLE_Payments",
    "/about": "page_About",
    "/join-team": "page_JoinTeam",
    "/join-form": "page_JoinForm",
    "/blog": "page_Blog",
    "/blog/detail": "page_BlogDetail",
    "/contact": "CONTACT_PAGE_TITLE",
    "/privacy": "PRIVACY_PAGE_TITLE",
    "/terms": "TERMS_PAGE_TITLE",
    "/faq": "page_FAQ",
    "/auth/login": "page_Login",
    "/auth/forgot-password": "page_ForgotPassword",
    "/auth/riset-password": "page_ResetPassword",
    "/auth/new-password": "page_NewPassword"
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();

    const currentPath = location.pathname;
    document.title = t(pageTitles[currentPath]) || "Talynx";
  }, [location]);

  return (
    <>
      <ToastContainer autoClose={2000} />
      <CookieBanner />
      <Routes>
        {/* <Route path="auth" element={<AuthLayout />}>
          <Route index path="login" element={<Login />} />
          <Route index path="forgot-password" element={<ForgotPassowrd />} />
          <Route index path="riset-password" element={<ResetPassword />} />
          <Route index path="new-password" element={<NewPassword />} />
        </Route> */}
        <Route path="" element={<DefaultLayout />}>
          <Route index element={<Homepage />} />
          {/* <Route path="pricing" element={<Pricing />} /> */}
          {/* <Route path="solutions" element={<Services />} /> */}
          {/* <Route path="plans-details" element={<PlansDetails />} /> */}
          {/* <Route path="payment" element={<Payment />} /> */}
          {/* <Route path="about" element={<About />} /> */}
          {/* <Route path="join-team" element={<JoinTeam />} /> */}
          {/* <Route path="join-form" element={<JoinForm />} /> */}
          {/* <Route path="blog" element={<Blog />} /> */}
          {/* <Route path="blog/detail" element={<BlogDetail />} /> */}
          <Route path="contact" element={<Contact />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="faq" element={<Faq />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
