import React, { useState } from 'react'
import { Fragment } from 'react'
import {  Container } from 'react-bootstrap'
import HeadTitle from '../Components/Pages/HeadTitle'
import ContactForm from '../Components/Pages/ContactForm'
import { useTranslation } from 'react-i18next';

const Faq = () => {
    const { t } = useTranslation();

    const FaqData = [
        {
            title: t("FAQ_QUESTION_1"),
            desc: t("FAQ_ANSWER_1")
        },
        {
            title: t("FAQ_QUESTION_2"),
            desc: t("FAQ_ANSWER_2")
        },
        {
            title: t("FAQ_QUESTION_3"),
            desc: t("FAQ_ANSWER_3")
        },
        {
            title: t("FAQ_QUESTION_4"),
            desc: t("FAQ_ANSWER_4")
        },
        {
            title: t("FAQ_QUESTION_5"),
            desc: t("FAQ_ANSWER_5")
        },
        {
            title: t("FAQ_QUESTION_6"),
            desc: t("FAQ_ANSWER_6")
        }
    ]

    const [toogleAccordion, settoogleAccordion] = useState(-1)

    const toogelAccordionWrap = (e) => {
        if (e === toogleAccordion) {
            settoogleAccordion(-1)
        } else {
            settoogleAccordion(e)
        }
    }
    return (
        <Fragment>
            <HeadTitle text={t("FAQ_H1_TITLE")} sub={t("FAQ_DESC")} />

            <section className='pb-0 md:!pb-[80px]'>
                <Container>
                    {
                        FaqData.map((obj, i) => {
                            return <Fragment key={i}>
                                <div>
                                    <div className="py-3 flex justify-between items-center cursor-pointer " onClick={() => toogelAccordionWrap(i)}>
                                        <h5 className='font-medium text__24'>{obj.title}</h5>
                                        <div className={"arrowPlus relative w-[24px] h-[24px] " + (toogleAccordion === i ? "active" : "")}>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>

                                    <div className={toogleAccordion === i ? "block" : "hidden"}>
                                        <p className='text__18'>{obj.desc}</p>
                                    </div>
                                </div>
                                <hr className='my-3 !border-[#E5E5E5]' />
                            </Fragment>
                        })
                    }
                </Container>
            </section>

            <ContactForm />
        </Fragment>
    )
}

export default Faq
