import React from 'react'
import { useState } from 'react'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Col, Row, Container, Form } from 'react-bootstrap'
import { DownIcon } from '../Icon/Icon'
import { NavLink } from 'react-router-dom'

const PriceList = () => {
    const { t } = useTranslation()
    const [ToogleAccordion, setToogleAccordion] = useState("Pro")

    const setToogleAccordionWrap = (e) => {
        if (e === ToogleAccordion) {
            setToogleAccordion("")
        } else {
            console.log(e);
            setToogleAccordion(e)
        }
    }

    const priceByStatus = {
        monthly: {
            label: t('PRICING_MONTH'),
            free: 0,
            pro: 399,
            entreprise: 249,
        },
        yearly: {
            label: t('PRICING_YEAR'),
            free: 0,
            pro: 4229,
            entreprise: 3229.2,
        }
    }

    const [prices, setTogglePrice] = useState(priceByStatus.monthly)


    return (
        <Fragment>
            <section>
                <Container>
                    <div className="mb-10 text-center">
                        <h2 className='font-medium text__48 mb-10'>{t('PRICING_TITLE')}</h2>

                        {/* <div className="flex justify-center items-center gap-2">
                            <h5 className='font-medium text__18'>{t('PRICING_MONTHLY')}</h5>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                className='checkLarge'
                                onChange={() => setTogglePrice(JSON.stringify(prices) === JSON.stringify(priceByStatus.monthly) ? priceByStatus.yearly : priceByStatus.monthly)}
                            />
                            <h5 className='font-medium text__18 text-Mpurple'>{t('PRICING_YEARLY')}</h5>
                            <h5 className='font-medium text__14 ml-3 text-Mpurple'>{t('PRICING_PROMO')}</h5>
                        </div> */}
                    </div>
                    <Row>
                        <Col className='mb-4 md:!mb-0' md={4}>
                            <div className="p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg text-center">
                                <h5 className='font-semibold text-lg mb-3'>{t('PRICING_LEVEL_1')}</h5>
                                <h2 className='font-bold text-3xl'>
                                    {/* <span className='text-base mb-2'>{prices.free}</span>
                                    <span className='text-xl'>/{prices.label}</span> */}
                                </h2>
                                <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_LEVEL_1_DESC')}</p>

                                <NavLink to="/contact" className='my-4 inline-block cursor-pointer font-semibold text-base bg-Mpurple hover:bg-Mpurple/90 text-white px-6 py-2 rounded-md shadow-sm'>{t('PRICING_DEMO_BUTTON')}</NavLink>

                                <div className='flex justify-around text-center w-full mt-6 mb-4 border-t border-gray-200 dark:border-gray-700 pt-4'>
                                    <div>
                                        <h6 className='font-semibold text-xl'>1</h6>
                                        <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_TEAM')}</p>
                                    </div>
                                    <div>
                                        <h6 className='font-semibold text-xl'>2</h6>
                                        <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_JOB')}</p>
                                    </div>
                                    <div>
                                        <h6 className='font-semibold text-xl'>0</h6>
                                        <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_EVAL')}</p>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between py-3 border-t border-gray-200 dark:border-gray-700 cursor-pointer" onClick={() => setToogleAccordionWrap("Free")}>
                                    <h5 className='font-medium text-gray-600 dark:text-gray-400'>{t('PRICING_SUMMARY_BUTTON')}</h5>
                                    <img src="./../images/icons/chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion === "Free" ? "rotate-180" : "")} alt="" />
                                </div>

                                <div className={ToogleAccordion === "Free" ? "block" : "hidden"}>
                                    <div className="text-sm text-gray-600 dark:text-gray-400 mt-4">
                                        <p>{t('PRICING_ITEM_1')}</p>
                                        <p>{t('PRICING_ITEM_2A')}</p>
                                        <p>{t('PRICING_ITEM_3A')}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col className='mb-4 md:!mb-0' md={4}>
                            <div className="p-6 rounded-xl text-center text-white  bg-Mpurple">
                                <h5 className='font-semibold text-lg mb-3'>{t('PRICING_LEVEL_2')}</h5>
                                <h2 className='font-bold text-3xl'>
                                    {/* <span className='text-base mb-2'>{prices.pro}€</span>
                                    <span className='text-xl'>/{prices.label}</span> */}
                                </h2>
                                <p className='text-gray-300'>{t('PRICING_LEVEL_2_DESC')}</p>

                                <NavLink to="/contact" className='my-4 inline-block cursor-pointer font-semibold text-base  bg-white hover:bg-blue-900 text-Mpurple px-6 py-2 rounded-md shadow-sm'>{t('PRICING_DEMO_BUTTON')}</NavLink>

                                <div className='flex justify-around text-center w-full mt-6 mb-4 border-t border-gray-600 pt-4'>
                                    <div>
                                        <h6 className='font-semibold text-xl'>
                                            <span className="md:hidden">∞</span>
                                            <span className="hidden md:inline">{t('PRICING_UNLIMITED')}</span>
                                        </h6>
                                    <p className='text-gray-300'>{t('PRICING_TEAM')}</p>
                                    </div>
                                    <div>
                                        <h6 className='font-semibold text-xl'>10</h6>
                                        <p className='text-gray-300'>{t('PRICING_JOB')}</p>
                                    </div>
                                    <div>
                                        <h6 className='font-semibold text-xl'>100/job</h6>
                                        <p className='text-gray-300'>{t('PRICING_EVAL')}</p>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between py-3 border-t border-gray-600 cursor-pointer" onClick={() => setToogleAccordionWrap("Pro")}>
                                    <h5 className='font-medium text-gray-300'>{t('PRICING_SUMMARY_BUTTON')}</h5>
                                    <div className={"transition-all duration-500 " + (ToogleAccordion === "Pro" ? "rotate-180" : "")}>
                                        <DownIcon color={"white"} />
                                    </div>
                                </div>

                                <div className={ToogleAccordion === "Pro" ? "block" : "hidden"}>
                                    <div className="text-sm text-gray-300 mt-4">
                                        <p>{t('PRICING_ITEM_1')}</p>
                                        <p>{t('PRICING_ITEM_2B')}</p>
                                        <p>{t('PRICING_ITEM_3B')}</p>
                                        <p>{t('PRICING_ITEM_4')}</p>
                                        <p>{t('PRICING_ITEM_5')}</p>
                                        <p>{t('PRICING_ITEM_6')}</p>
                                        <p>{t('PRICING_ITEM_7')}</p>
                                        <p>{t('PRICING_ITEM_8')}</p>
                                        <p className='line-through'>{t('PRICING_ITEM_9')}</p>
                                        <p className='line-through'>{t('PRICING_ITEM_10')}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col className='mb-4 md:!mb-0' md={4}>
    <div className="p-6 bg-white dark:bg-gray-800 rounded-xl shadow-lg text-center">
        <h5 className='font-semibold text-lg mb-3'>{t('PRICING_LEVEL_3')}</h5>
        <h2 className='font-bold text-3xl'>
            {/* <span className='text-base mb-2'>{prices.entreprise}€</span> */}
            {/* <span className='text-xl'>/{prices.label}</span> */}
        </h2>
        <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_LEVEL_3_DESC')}</p>

        <NavLink to="/contact" className='my-4 inline-block cursor-pointer font-semibold text-base bg-Mpurple hover:bg-Mpurple/90 text-white px-6 py-2 rounded-md shadow-sm'>{t('PRICING_SALES_BUTTON')}</NavLink>

        <div className='flex justify-around text-center w-full mt-6 mb-4 border-t border-gray-200 dark:border-gray-700 pt-4'>
            <div>
                <h6 className='font-semibold text-xl'>
                    <span className="md:hidden">∞</span>
                    <span className="hidden md:inline">{t('PRICING_UNLIMITED')}</span>
                </h6>
                <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_TEAM')}</p>
            </div>
            <div>
                <h6 className='font-semibold text-xl'>
                    <span className="md:hidden">∞</span>
                    <span className="hidden md:inline">{t('PRICING_UNLIMITED')}</span>
                </h6>
                <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_JOB')}</p>
            </div>
            <div>
                <h6 className='font-semibold text-xl'>
                    <span className="md:hidden">∞</span>
                    <span className="hidden md:inline">{t('PRICING_UNLIMITED')}</span>
                </h6>
                <p className='text-gray-600 dark:text-gray-400'>{t('PRICING_EVAL')}</p>
            </div>
        </div>

        <div className="flex items-center justify-between py-3 border-t border-gray-200 dark:border-gray-700 cursor-pointer" onClick={() => setToogleAccordionWrap("Enterprise")}>
            <h5 className='font-medium text-gray-600 dark:text-gray-400'>{t('PRICING_SUMMARY_BUTTON')}</h5>
            <img src="./../images/icons/chevron-down-line.svg" className={"transition-all duration-500 " + (ToogleAccordion === "Enterprise" ? "rotate-180" : "")} alt="" />
        </div>

        <div className={ToogleAccordion === "Enterprise" ? "block" : "hidden"}>
            <div className="text-sm text-gray-600 dark:text-gray-400 mt-4">
                <p>{t('PRICING_ITEM_1')}</p>
                <p>{t('PRICING_ITEM_2B')}</p>
                <p>{t('PRICING_ITEM_3B')}</p>
                <p>{t('PRICING_ITEM_4')}</p>
                <p>{t('PRICING_ITEM_5')}</p>
                <p>{t('PRICING_ITEM_6')}</p>
                <p>{t('PRICING_ITEM_7')}</p>
                <p>{t('PRICING_ITEM_8')}</p>
                <p>{t('PRICING_ITEM_9')}</p>
                <p>{t('PRICING_ITEM_10')}</p>
            </div>
        </div>
    </div>
</Col>

                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default PriceList
