import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-Mpurple">
      <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
          <div className="border-t border-gray-200 text-center pt-8">
            <h1 className="text-5xl mb-3 font-bold text-Mpurple">{t("404_H1_TITLE")}</h1>
            <p className="pb-8 px-12 font-medium">{t("404_DESC")}</p>
            <Link to="/">
            <button className='font-medium text-[14px] bg-Mpurple text-Mwhite px-4 py-2 rounded-full'>{t("404_BUTTON")}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;