import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookiePreference = localStorage.getItem('cookiePreference');
    if (cookiePreference === null) {
      setShowBanner(true);
    }
  }, []);

  const handleCookiePreference = (accept) => {
    localStorage.setItem('cookiePreference', accept ? 'accepted' : 'rejected');
    setShowBanner(false);
    // Here you would typically trigger functions to enable or disable cookies based on the user's choice
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-Mpurple text-white px-4 py-4 sm:px-6 z-50">
      <div className="max-w-7xl mx-auto flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center mb-3 sm:mb-0">
          <svg className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <p className="text-sm">{t("COOKIE_BANNER_MESSAGE")}</p>
        </div>
        <div className="flex space-x-4">
          {/* <button
            onClick={() => handleCookiePreference(false)}
            className="bg-gray-700 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            Reject
          </button> */}
          <button
            onClick={() => handleCookiePreference(true)}
            className="bg-white text-gray-900 px-4 py-2 rounded-md text-sm font-medium hover:bg-gray focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
          >
            {t("COOKIE_BANNER_ACCEPT_BUTTON")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;