import React, { Fragment, useState, useEffect } from 'react'
import { Container, Dropdown } from 'react-bootstrap'
import { NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Globe } from 'react-bootstrap-icons';

const Navbar = () => {
    const { t, i18n } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
    };

    let location = useLocation();
    const [navabarScroll, setnavabarScroll] = useState(location.pathname === "/" ? true : false)

    const stickNavabr = () => {
        if (window.scrollY > 100) {
            setnavabarScroll(true)
        } else {
            location.pathname !== "/" ? setnavabarScroll(false) : setnavabarScroll(true)
        }
    }

    useEffect(() => {
        location.pathname !== "/" ? setnavabarScroll(false) : setnavabarScroll(true)
        window.addEventListener('scroll', stickNavabr);
    }, [location])
    
    return (
        <Fragment>
        <div className={'fixed py-4 w-full z-[999] left-0 top-0 ' + (navabarScroll ? "bg-Mpurple " : "bg-white")}>
          <Container className='relative flex items-center justify-between'>
            <NavLink to="/" className=''>
              <img src={navabarScroll ? "./../images/logo.svg" : "./../images/purple_logo.svg"} width={50} height={50} alt="" />
            </NavLink>
  
            {/* Version desktop */}
            <div className="hidden lg:flex items-center gap-4">
              <NavLink to="/contact" className={'font-medium ' + (navabarScroll ? "text-Mwhite" : "text-Mblack")}>
                {t('NAVBAR_CONTACT_BUTTON')}
              </NavLink>

              {/* Séparateur */}
              <div className={'h-4 w-px ' + (navabarScroll ? "bg-white" : "bg-gray-500")}></div>
              
              <Dropdown show={dropdownOpen} onClick={toggleDropdown}>
                <Dropdown.Toggle id="dropdown-language" variant="transparent" className={"d-flex align-items-center " + (navabarScroll ? "text-Mwhite" : "text-Mblack")}>
                    {i18n.language.toUpperCase()}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {Object.keys(i18n.options.resources).map((lng) => (
                        <Dropdown.Item
                            key={lng}
                            onClick={() => changeLanguage(lng)}
                            disabled={i18n.language === lng}
                            className="cursor-pointer no-hover"
                        >
                            {lng.toUpperCase()}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>

              <NavLink to="https://app.talynx.io">
                <div className={'px-4 py-2 rounded font-medium text__14 ' + (navabarScroll ? "bg-Mwhite hover:bg-Mwhite/90 text-Mblack" : "bg-Mpurple hover:bg-Mpurple/90 text-Mwhite")}>
                  {t('NAVBAR_LOGIN_BUTTON')}
                </div>
              </NavLink>
            </div>
          
            {/* Version mobile */}
            <div className="lg:hidden flex items-center gap-4">
              {/* Icône de langue */}
              <div className="relative">
                <Globe size={24} onClick={toggleDropdown} className={"cursor-pointer " + (navabarScroll ? "text-Mwhite" : "text-Mblack")} />
                {dropdownOpen && (
                  <div className="absolute right-0 mt-2 bg-white border rounded shadow-lg">
                    {Object.keys(i18n.options.resources).map((lng) => (
                      <div
                        key={lng}
                        onClick={() => {
                          changeLanguage(lng);
                          setDropdownOpen(false);
                        }}
                        className={`px-4 py-2 cursor-pointer ${i18n.language === lng ? 'font-bold' : ''}`}
                      >
                        {lng.toUpperCase()}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {/* Icône de connexion */}
              <NavLink to="https://app.talynx.io">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={navabarScroll ? "white" : "black"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </NavLink>
            </div>
          </Container>
        </div>
      </Fragment>
    )
}

export default Navbar